import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://guide.msoftit.com/api/'; //Production
// const API_URL = 'http://127.0.0.1:8000/api/'; //Demo

class UserService {

  authPostRequest(url, request) {
    return axios.post(API_URL + url, request, { headers: authHeader() });
  }

  authGetRequest(url, request) {
    return axios.get(API_URL + url, request, { headers: authHeader() });
  }

  PostRequest(url, request) {
    return axios.post(API_URL + url, request);
  }

  getOtp(url, request) {
    return axios.post(API_URL + url, request);
  }

  verifyOtp(url, request) {
    return axios.post(API_URL + url, request);
  }

  resetPin(url, request) {
    return axios.post(API_URL + url, request, { headers: authHeader() });
  }

  addAdmin(url, request) {
    return axios.post(API_URL + url, request, { headers: authHeader() });
  }

  getAdminList(url, request) {
    return axios.post(API_URL + url, request, { headers: authHeader() });
  }

  getSubLevels(url, request) {
    return axios.post(API_URL + url, {
      sub_level_type: request.sub_level_type,
      id: request.id,
      lajna:request.lajna
    }, { headers: authHeader() });
  }

  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  getCombobox(request) {
    return axios.post(API_URL + 'get-combobox', request, { headers: authHeader() });
  }

  GetRequest(url, request) {
    return axios.get(API_URL + url, request);
  }

  gotoCourses(request) {
    return axios.post('https://courses.wisdomislam.org/guide', request);
  }

}

export default new UserService();
