<template>
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section pt-0 mt-0" v-if="!loading_list">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 mal-text text-primary">{{ responseData.report_title }}</h3>
        </div>
    </div>

    <div class="section" v-if="!loading_list">
        <div class="row mt-1" v-if="responseData.summary">
            <div class="col-12" v-if="responseData.summary">
                <div class="stat-box text-center">
                    <div class="title mal-text">Total Collection</div>
                    <div class="value text-dark" style="font-size: 32px !important;">
                        <span class="text-success">{{ responseData.summary ?
                            responseData.summary.total : '00'
                        }}
                        </span>

                    </div>
                </div>
            </div>
            <div class="col-6" v-if="responseData.summary">
                <div class="stat-box text-center mt-1">
                    <div class="title mal-text">Today</div>
                    <div class="value text-dark">
                        <span class="text-primary">{{ responseData.summary ?
                            responseData.summary.today_total : '00'
                        }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-6" v-if="responseData.summary">
                <div class="stat-box text-center mt-1">
                    <div class="title mal-text">This Week</div>
                    <div class="value text-dark">
                        <span class="text-primary">{{ responseData.summary ?
                            responseData.summary.week_total : '00'
                        }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="listed-detail mt-0">
        <h4 class="text-center mt-0" v-if="!loading_list && !responseData.summary.total">No Data</h4>
    </div>
    <div class="section mt-2 mb-2" v-if="!loading_list && responseData.summary.total">
        <div class="card">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col" v-if="user_level <= 3">Today</th>
                            <th scope="col" v-if="user_level <= 3">Total</th>
                            <th scope="col" v-if="user_level == 4">Mobile</th>
                            <th scope="col" v-if="user_level == 4">Amount</th>
                        </tr>
                    </thead>
                    <tbody v-if="user_level <= 3">
                        <tr v-for="(sub_level, index) in responseData.summary.areas" v-bind:key="sub_level">
                            <td class="text-primary font-dark">
                                <span class="badge badge-success" v-if="index <= 4">{{ index + 1 }}</span>
                                <span class="badge badge-secondary" v-else>{{ index + 1 }}</span>
                            </td>
                            <td class="text-primary font-dark">
                                <router-link v-if="user_level <= 3 || user_level <= 3"
                                    :to="'/collection-report/' + (user_level + 1) + '/' + sub_level.id + '/' + $route.params.type">
                                    {{ sub_level.name }}
                                </router-link>
                                <!-- <router-link v-if="user_level == 3" :to="">
                                    {{ sub_level.name }}
                                </router-link> -->
                            </td>
                            <td class="text-primary font-dark">
                                {{ sub_level.today_total }}
                            </td>
                            <td class="text-primary font-dark">
                                {{ sub_level.total }}
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="user_level == 4">
                        <tr v-for="(receipt, index) in responseData.receipts" v-bind:key="receipt">
                            <td class="text-primary font-dark">
                                <span class="badge badge-success" v-if="index <= 4">{{ index + 1 }}</span>
                                <span class="badge badge-secondary" v-else>{{ index + 1 }}</span>
                            </td>
                            <td class="text-primary font-dark">
                                {{ receipt.name }}
                            </td>
                            <td class="text-primary font-dark">
                                {{ receipt.mobile }}
                            </td>
                            <td class="text-primary font-dark">
                                {{ receipt.amount }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import UserService from "../../services/user.service";
import $ from 'jquery';
import AlertModel from "../layouts/AlertModel.vue";
import DialogModel from "../layouts/DialogModel.vue";
export default {
    name: 'Collection Report',
    components: {
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            user_level: "",
            user_level_id: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            permissions: [],
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            level: [],
            page_title: 'റിപ്പോർട്ട്',
            responseData: {
                summary: []
            }


        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.count_target - this.count_total;
            if (value <= 0) {
                return '0';
            } else {
                return value;
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.user_level);
            this.user_level_id = parseInt(this.$route.params.id);
            this.getReport();
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getReport() {
            this.loading_list = true;
            let data = {
                user_level: parseInt(this.$route.params.user_level),
                user_level_id: parseInt(this.$route.params.id),
                type: this.$route.params.type
            }
            UserService.authPostRequest(`get-receipt-report`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.page_title = this.responseData.page_title;
                        this.$root.updateParent(this.responseData.report_title);
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        }
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;

}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.wallet-card .balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    height: auto;
}

.left {
    margin-left: 20px;
}

.wallet-card {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 0px 16px;
    position: relative;
    z-index: 1;
}

.bg-pink {
    background-color: #E6A6C7 !important;
}

.bg-pink-dark {
    background-color: #B85887 !important;
}
</style>